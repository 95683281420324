/* Variables */
:root {
  --primary-color: #2f66f2; /* primary color for all components */
  --primary-color-rgb: 47, 102, 242; /*  primary color rgb  */

  --link-color: #2f66f2; /*  link color */
  --success-color: #00892d; /*  success state color */
  --warning-color: #faad14; /*  warning state color */
  --error-color: #f05822; /*  error state color */

  --font-size-base: 16px; /*  major text font size */
  --font-size-base-secondary: 14px; /*  check this line */
  --font-size-base-small: 13px; /*  check this line */
  --heading-color: rgba(0, 0, 0); /*  heading text color */
  --text-color: #444444; /*  major text color */
  --text-color-secondary: rgba(0, 0, 0, 0.45); /*  secondary text color */
  --disabled-color: rgba(0, 0, 0, 0.25); /*  disable state color */

  --border-radius-base: 10px; /*  major border radius */
  --border-color-base: #d9d9d9; /*  major border color */
  --box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); /*  major shadow for layers */

  --red: #ed1c24; /*  red color */
  --red-rgb: 237, 28, 36; /*  red color rgb */
  --red-dark: #9d0a0e; /*  red color dark */
  --red-dark-rgb: 158, 11, 15; /*  red color dark rgb  */

  --exxon-blue-70: #4c6dbf; /*  Exxon Mobil Blue 70% */
  --exxon-blue-70-rgb: 82, 133, 196; /*  Exxon Mobil Blue 70% rgb  */
  --exxon-blue-60: #6682cb; /*  Exxon Mobil Blue 60% */
  --exxon-blue-60-rgb: 106, 145, 203; /*  Exxon Mobil Blue 60% rgb */

  --blue: #2f66f2; /* primary color for all components */
  --blue-rgb: 47, 102, 242; /*  primary color rgb  */
  --blue-dark: #2249c0; /*   primary tint color */
  --blue-dark-rgb: 34, 73, 192; /* primary tint color rgb */

  --orange: #f05822; /*  orange color, not expected */
  --orange-rgb: 240, 88, 34; /*  orange color rgb */
  --orange-dark: #bf3d0d; /* Orange color dark */
  --orange-dark-rgb: 191, 61, 13; /* Orange color dark rgb */

  --black-70: #4d4d4d; /*  black 70% */
  --black-70-rgb: 09, 110, 113; /*  black 70% rgb */
  --black-50: #808080; /*  black 50% */ /*  */
  --black-50-rgb: 47, 149, 152; /*  black 50% rgb */
  --black-40: #999999; /*  black 40% */
  --black-40-rgb: 67, 169, 172; /*  black 40% rgb */
  --black-35: #a6a6a6; /*  black 35% */
  --black-35-rgb: 77, 179, 182; /*  black 35% rgb */
  --black-20: #cccccc; /*  black 20% */
  --black-20-rgb: 209, 211, 212; /*  black 20% rgb */
  --black-15: #d9d9d9; /*  black 15% */
  --black-15-rgb: 220, 221, 222; /*  black 15% rgb */
  --black-10: #e6e6e6; /*  black 10% */
  --black-10-rgb: 230, 231, 232; /*  black 10% rgb */
  --black-5: #f2f2f2; /*  black 5% */
  --black-5-rgb: 241, 242, 242; /*  black 5% rgb */

  --gray: #eeeeee; /*rgba(238,238,238,255)*/

  --footer-background: black;
  --footer-color: white;
  --footer-background-dark: #444444;
  --footer-color-dark: white;

  --whatsapp-color: #075e54;

  --background: #ffffff;
  --font-family: "EMPrint", sans-serif;

  /* fixed heights */
  --navbar-height: 50px;
  --sub-navbar-height: 130px;
  --both-navbars-height: 180px;

  /* Legacy variables */
  --carker-blue: #2f66f2;
  --black-color: #000000;
  --white-color: #ffffff;
  --royal-blue-color: #2c6fd1;
  --royal-sky-blue-color: #5696e2;
  --royal-light-blue-color: #0c479d;
  --royal-dark-blue-color: #002b6a;
  --smoke-color: #f8f8f8;
  --charcoal-color: #454545;
  --zumthor-color: #d1d3d4;
  --red-color: #fe000c;
  --gray-color: #6d6e71;
  --gray-light-color: #939598;
  --tangerine-dark-color: #f6a314;
  --tangerine-light-color: #fcdc85;
  --solitude-color: #f1f5ff;
  --eucalyptus-color: #299f5b;
  --eucalyptus-light-color: #4bca81;
  --orange-color: #ce3003;
  --pink-color: #ff8f95;
  --pink-pale-color: #ffe8e9;
  --red-dark-color: #881e00;
  --oasis-color: #fff1cc;
  --safron-color: #f2bf33;
  --safron-dark-color: #ed8b00;
  --bubbles-color: #eafcf1;
  --green-light-color: #97e6b8;
  --green-color: #086e33;
  --fc-border-color: #9395981a;

  --CREAT-color: #febf1f; /*OLD #ffcc00   */ /* 'Confimarción del cliente pendiente' */
  --PEND-color: #3399ff; /*OLD #ff9900   */ /* 'Confirmación Pendiente' */
  --APPO-color: #3366ff; /*OLD #ff3366   */ /* 'Cita confirmada' */
  --RECEP-color: #ff6699; /*OLD #9900cc   */ /* 'Recepción' */
  --DIAG-color: #cc3399; /*OLD #3333cc   */ /* 'En diagnósito' */
  --QUOT-color: #be9dff; /*OLD #6699ff   */ /* 'Cotización' */
  --PROG-color: #69d9b4; /*OLD #339999   */ /* 'Reparando' */
  --READY-color: #35cd02; /*OLD #00cc00   */ /* 'Terminado' */
  --CANCE-color: #ff0000; /*OLD #999999   */ /* 'Cancelado' */
  --CLOS-color: #339933; /*OLD #444444   */ /* 'Entregado' */

  --background-contrast-color: #303030;
}
/* /Variables */
