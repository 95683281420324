.root-background {
  background: #fff !important;
}

#navbar {
  transition: top 1.5s;
}

#navbars {
  transition: top 1.5s;
}

.root {
  height: 100%;
}

.gray-background {
  background: #f8f8f8 !important;
}

.is-mobile {
  /* display: block;   */
}

.mobile-block {
  display: none !important;
}

.mobile-block-exp {
  display: none !important;
}

.ios-map {
  position: fixed !important;
}

.g-map {
  position: relative !important;
}

.ant-btn-primary {
  text-shadow: none !important;
}

.register {
  font-size: 12px;
  text-decoration: underline;
  color: white;
  font-family: "Emprint-regular";
}

span.input-navbar-name input.ant-input {
  border-inline-end: 1px solid;
  border-radius: 0px;
  border-color: #bfbfbf;
}

h4 {
  color: #ffffff;
}

div.workshop-type-cards::-webkit-scrollbar,
.workshop-services-container::-webkit-scrollbar {
  width: 0.15em;
  height: 0.5em;
  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: none;
  /* Firefox 64 */
}

div.workshop-type-cards::-webkit-scrollbar-track,
.workshop-services-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.288);
  background: #fff;
  border-radius: 10px;
}

div.workshop-type-cards::-webkit-scrollbar-thumb,
.workshop-services-container::-webkit-scrollbar-thumb {
  background: rgba(209, 211, 212, 0.267);
}

/* Handle on hover */

div.workshop-type-cards::-webkit-scrollbar-thumb:hover,
.workshop-services-container::-webkit-scrollbar-thumb:hover {
  background: #8080809a;
}

.end-col-container {
  margin: 2.5%;
}

.workshop-type-card {
  height: 170px;
  width: calc(25% - 25px);
  background-color: #f1f5ff;
  margin: 0.5%;
  display: inline-block;
  position: relative;
  border-radius: 0px;
  transition: 0.4s;
  cursor: pointer;
  transform: skew(-20deg);
}

.workshop-type-card:hover {
  background-color: rgba(189, 215, 255, 0.5);
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.workshop-type-card:active {
  background: rgba(189, 215, 255, 0.9);
  box-shadow: none;
  transition: 0.8s;
}

.workshop-type-card .workshop-type-card-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) skew(20deg);
}

.workshop-card {
  width: 409px;
  background-color: white;
  margin: 0.5%;
  display: inline-block;
  position: relative;
  border-radius: 3%;
  transition: 0.4s;
  box-shadow:
    0 0.25px 0.5px -2px rgba(0, 0, 0, 0.16),
    0 0.75px 1.5px 0 rgba(0, 0, 0, 0.12),
    0 1.25px 3px 1px rgba(0, 0, 0, 0.09);
}

.workshop-card:hover {
  background-color: transparent;
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.workshop-img-container {
  width: 409px;
  height: 230px;
}

.workshop-img-container img {
  width: 100%;
  height: 210px;
  border-radius: 10px 10px 0px 0px;
}

.workshop-info div {
  display: inline-block;
}

.workshop-status .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.end-button {
  width: "337px";
}

.img-wha {
  margin-right: 0.4rem;
  height: 18px;
}

.link {
  color: #3f45f6;
  font-family: EMprintW01;
}

.img-head {
  float: left;
  width: 180px;
}

.three-columns {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.two-columns {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  width: 100%;
  text-align: left;
}

.li-margin {
  margin-bottom: 0.8rem;
}

.first-header {
  width: 100%;
  height: 50px;
  background: #2f66f2;
  position: fixed;
}

.left-items {
  float: left;
  height: inherit;
  line-height: 50px;
  overflow: hidden;
  display: block;
}

.right-items {
  height: inherit;
  line-height: 50px;
  float: right;
}

.second-header {
  width: 100%;
  height: 110px;
  background: #ffffff;
  margin-top: 50px;
  position: fixed;
  display: block;
  line-height: 110px;
}

.footer {
  width: 100%;
  height: 308px;
  background-color: #f5f5f5;
}

.footer-text {
  color: #6d6d6d;
  font-size: 16px;
}

.second-footer {
  height: 219px;
  background-color: #fdfdfd;
}

.second-footer-text {
  font-family: "Emprint-light";
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: #adadad;
}

.header-pad {
  min-height: 70%;
  min-width: 100%;
  background-size: 100% 100%;
  height: 700px;
}

.row-margin {
  margin-top: 0.8rem;
}

.ant-row {
  row-gap: 0px !important;
}

.end-header {
  height: 100%;
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.end-header div {
  display: inline-block;
}

.end-header-inputs .inputs-container {
  border: 0.5px solid var(--gray-light-color);
  border-radius: 15%;
  width: calc(90%-15px);
  height: 90%;
}

.title-text {
  color: #282828;
  font-size: 47px;
  font-family: "Emprint-regular";
  margin: 0 auto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.title-text > strong {
  font-weight: 600;
  color: #1820ff;
  font-size: 48px;
}

.subtitle-text {
  font-family: "Emprint-regular";
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828;
}

.second-title-text {
  font-family: "Emprint-regular";
  font-size: 33px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #ffffff;
}

.video-size {
  width: 100%;
}

.card-border {
  border: solid;
  border-radius: 100%;
  border-color: #5c62ff;
}

.card {
  font-family: EMprintW01Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #282828;
}

.card-specialty-title {
  font-family: EMprintW01;
  font-style: italic;
  font-weight: normal;
}

.card-specialty {
  font-family: EMprintW04;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 0.44px;
}

.card-address {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.44px;
}

.whitecard-border {
  border: solid;
  border-radius: 100%;
  border-color: #ffffff;
}

.whitecard-text {
  font-family: "Emprint-light";
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
}

.whitecard-name {
  font-weight: normal;
  font-style: italic;
  text-align: right;
}

.mapping {
  margin-top: -20rem;
}

.map-box {
  background: #2f66f2;
  color: #ffffff;
  padding: 0 15px 0 40px;
  line-height: 30px;
  height: 30px;
  font-weight: bold;
}

.map-box-off {
  background: #979797;
}

.pad {
  width: 20px;
  position: relative;
  margin-left: -28px;
}

.pad-on {
  background: #3f45f6;
}

.pad-off {
  background: #979797;
}

.arrow {
  width: 0px;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 15px solid #2f66f2;
  border-bottom: 15px solid transparent;
}

.arrow-on {
  border-left: 15px solid #3f45f6;
}

.arrow-off {
  border-left: 15px solid #979797;
}

.arrow-neutral {
  border-left: 15px solid #ffffff;
}

.right-map-box {
  position: absolute;
  background: #f05822;
  color: white;
  padding: 10px;
  border-radius: 0 10px 0 10px;
  right: 0;
  z-index: 1;
}

.bottom-map-box {
  position: absolute;
  background: #0008e6;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  z-index: 1;

  margin-bottom: 293px !important;
}

.down-map-box {
  height: auto;
  padding: 30px 18px;
  border-radius: 0 0 10px 10px;
  background: #f05822;
}

.filter.ant-popover-placement-top {
  position: absolute !important;
  z-index: 12;
}

.site-drawer-render-in-current-wrapper {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.filter
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  padding: 0 !important;
}

.filter
  .ant-select-selector
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 16px;
}

.ant-popover {
  border-radius: 15px;
}

.filter-input div.ant-popover-content div.ant-popover-inner {
  max-height: 400px;
  overflow-x: hidden;
}

.popover-filter {
  background: white;
  outline: none;
  line-height: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #a8a8a8;
  border-radius: 10px;
  padding: 12px 20px;
  cursor: pointer;
}

.input-filter {
  border-radius: 10px;
  background: white;
  padding: 10px 20px;
}

.input-filter input {
  line-height: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #a8a8a8;
}

.input-filter input::placeholder {
  font-size: 16px !important;
  color: #a8a8a8;
  text-align: left !important;
  font-family: "Emprint-regular" !important;
}

.input-filter.mobile-size input::placeholder {
  font-size: 12px !important;
  color: #a8a8a8;
}

.search {
  font-family: EMprint-regular;
  font-size: 18px;
  font-weight: 600;
  color: #a8a8a8;
  border: 0.5px solid;
  border-radius: 10px;
  width: calc(100% - 10px);
  margin: 5px;
}

.subtext {
  color: white;
  margin: 0;
}

.shadow-header {
  box-shadow:
    0 8px 6px -6px rgba(0, 0, 0, 0.25),
    0 16px 12px -12px rgba(0, 0, 0, 0.151);
}

.workshop-city {
  text-align: end;
}

#drawer .ant-drawer-content-wrapper {
  width: 60% !important;
}
.border-radius .ant-drawer-content {
  border-radius: 7px;
}

.border-radius input {
  border-radius: 10px;
}

.border-radius .ant-select-selector {
  border-radius: 6px !important;
}

.border-radius .ant-form-item-extra {
  font-family: EMprintW01;
  font-size: 14px;
  font-weight: 600;
  color: #a8a8a8;
}

.no-padding {
  padding: 0 !important;
}

.selected-images {
  border-radius: 10px;
  height: 80px;
  object-fit: cover;
}

.selected-images-space {
  height: 80px;
  padding-right: 10px !important;
}

.drawer-title {
  font-family: EMprintW04;
  font-size: 16px;
  font-weight: 600;
  color: #f05822;
}

.drawer-subtitle {
  font-family: EMprintW01;
  font-size: 14px;
  font-weight: 600;
  color: #f05822;
}

.ant-drawer-header {
  background: #f05822 !important;
}

.day-bubble-on {
  color: #007941;
  border: solid #007941;
  border-radius: 10px;
  background: #ecfff3;
  height: 100%;
}

.day-bubble-off {
  color: #a8a8a8;
  border: solid #a8a8a8;
  border-radius: 10px;
  background: #eeeeee;
  height: 100%;
}

.toUpper {
  text-transform: uppercase;
}

.toUpper input {
  text-transform: uppercase;
}

.toUpper::placeholder {
  text-transform: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-switch-checked {
  background-color: var(--blue) !important;
}

.unfocused {
  color: #40a9ff !important;
  border-color: #40a9ff !important;
}

.focused {
  color: #fff !important;
  border-color: #fff !important;
}

/* CSS STYLES FOR THE AUTO COMPLETE MAP */

.pac-container {
  background-color: #fff;
  z-index: 1000;
  font-size: 13px;
  overflow: hidden;
  width: 100%;
}

.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
  font-family: "Emprint-regular";
}

.pac-icon-marker {
  background-position: -1px -161px;
}

.pac-item {
  cursor: pointer;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  vertical-align: middle;
  text-align: left;
  color: #454545;
}

.pac-item.custom {
  background-color: #fff9c4;
}

.pac-item:hover {
  background-color: #f1f5ff;
  border-radius: 15px;
  border: none;
}

.pac-item-error,
.pac-item-error:hover {
  color: #aaa;
  padding: 0 5px;
  cursor: default;
  background-color: #fff;
}

.emergency-tag .ant-tag-close-icon {
  font-size: 14px !important;
  margin-left: 10px !important;
}

.wd-container {
  padding: 2%;
  margin: 0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.rating-collapse .ant-collapse-header {
  padding: 0 !important;
  padding-right: 10px !important;
}

.rating-collapse
  div.ant-collapse-header
  span.anticon.anticon-right.ant-collapse-arrow {
  position: absolute !important;
  top: 25% !important;
  transform: rotate(90deg) translate(0%, 25%) !important;
}

.ant-collapse-item-active.rating-collapse
  div.ant-collapse-header
  span.anticon.anticon-right.ant-collapse-arrow {
  transform: rotate(270deg) translate(0, 0) !important;
}

.rate-details {
  stroke: black;
}

.zindex-message {
  z-index: 3000;
}

.ant-spin-blur::after {
  opacity: 1 !important;
}

.spin-exxon {
  height: 100%;
}

.btn-on-map {
  cursor: pointer;
  margin: 15px;
  text-align: center;
  position: absolute;
  top: 9px !important;
}

.input-navbar input.ant-input {
  font-size: 18px;
}

.filter div.ant-select-selector span.ant-select-selection-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 3px;
  font-size: 18px;
}

.btn-icon span.anticon svg {
  transform: translate(0, 15%);
}

.promo-modal div.ant-modal-content {
  background-color: #fff;
  border-radius: 10px;
}

.promo-form {
  padding: 0 !important;
}

.ws-detail div.ant-collapse-header {
  padding: 12px 12px 12px 0 !important;
}

.worshop-carousel {
  bottom: -20% !important;
}

.worshop-carousel li.slick-active button {
  background: #002b6a !important;
  height: 4.5px !important;
}

.worshop-carousel li button {
  background: #5696e2 !important;
  height: 4.5px !important;
}

.circle {
  position: absolute;
  background: #3f45f6;
  z-index: 0;
  margin: -75px 0px 0px -75px;
  clip-path: circle(50% at 50% 50%);
  width: 280px;
  height: 280px;
}

.number-coloring {
  color: white;
  font-size: 18px;
}

.pointer {
  cursor: pointer;
}

.no-header .ant-picker-header {
  display: none;
}

.title-super {
  color: #f05822;
  font-size: 48px;
  font-family: Emprint-semibold;
  margin: 0;
}

.subtitle-super {
  color: #f05822;
  font-size: 13px;
  font-family: Emprint-semibold;
  margin: 0;
}

.back-table {
  border: 1px #a8a8a8 solid;
  padding: 16px;
  background: white;
  border-radius: 10px;
}

.tab-super {
  color: #f05822;
  font-family: Emprint-semibold;
  margin: 0 0.8rem 0 0;
  transition-delay: 50ms;
}

.tab-super-disabled {
  color: #a8a8a8;
  text-decoration: underline;
}

.text-super p {
  font-family: Emprint-semibold;
  font-size: 15px;
}

.text-super label {
  font-family: Emprint-regular;
  color: #a8a8a8;
  font-size: 11px;
}
.sider-title-text {
  color: #576271;
  font-size: 16px;
  font-family: emprint-semibold;
}
.sider-text {
  color: #576271;
  font-size: 14px;
  font-family: emprint-regular;
}
.title-space {
  display: inline-flex;
  width: 100%;
  place-content: space-between;
}

#form .ant-form {
  padding: 0 !important;
}

.ant-input-group-wrapper input {
  border-radius: 10px 0 0 10px;
}

.ant-input-group-wrapper span {
  border-radius: 0 10px 10px 0;
}

.ant-upload.ant-upload-drag {
  width: 160px;
  height: 90px;
}

.ant-image-mask {
  border-radius: 10px;
}

p.small.blue {
  font-family: Emprint-light;
  text-align: left;
  font-size: 20px;
  margin-bottom: 0;
  color: #2f66f2;
}

@media only screen and (max-width: 1024px) {
  p.small.blue {
    font-size: 12px;
  }

  .workshop-city {
    text-align: start;
  }
  .end-logo-container {
    width: 100px;
  }
  .responsive-header {
    padding: 0 3%;
  }
  p.responsive {
    font-size: 38.8px !important;
  }
  .end-col-container {
    margin: 0;
  }
  .workshop-type-card {
    margin: 0.75%;
    width: 190px;
    height: 115px;
  }
  .workshop-card {
    margin: 1%;
    /* width: calc(70% - 25px); */
  }
  div.workshop-type-cards::-webkit-scrollbar,
  .workshop-services-container::-webkit-scrollbar,
  .mobile::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
  .workshop-img-container img {
    width: 100%;
    /* height: 100%; */
    height: 171px;
  }
  .rating-collapse
    div.ant-collapse-header
    span.anticon.anticon-right.ant-collapse-arrow {
    transform: rotate(90deg) translate(0%, -60%) !important;
  }
  .ant-collapse-item-active.rating-collapse
    div.ant-collapse-header
    span.anticon.anticon-right.ant-collapse-arrow {
    transform: rotate(270deg) translate(0, 100%) !important;
  }
  .rating-mobile li {
    margin-right: 4px !important;
  }
}

@media only screen and (max-width: 992px) {
  .wd-container {
    padding: 0;
    margin: 0 !important;
  }
  .title-light.responsive {
    font-size: 26px !important;
    margin-top: 27px !important;
  }
  .second-title-text {
    font-size: 23px;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-block-exp {
    display: block !important;
  }
}

@media only screen and (max-width: 1300px) {
  .is-mobile {
    display: none;
  }
  /* .is-mobile {
    display: flex;
  } */
  .mobile-block {
    display: block !important;
  }
  .bottom-map-box {
    border-radius: 0 10px 0 0;
    bottom: 0 !important;
    position: relative !important;

    margin-bottom: 0px !important;
  }
  .link {
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    z-index: 2;
  }
  .register {
    margin-top: 0.8rem;
    font-size: 18px;
  }
  .img-head {
    width: 120px;
    float: none;
  }
  .second-header {
    height: 58px;
    line-height: 58px;
    display: inline-flex;
    place-content: space-between;
  }
  .mobile-button {
    background: rgb(24, 32, 255);
    color: white;
    font-size: 18px;
    padding: 0;
  }
  .second-footer {
    padding: 15px 0 0 0 !important;
    height: auto;
  }
  .btn-endconsumer-container.responsive {
    bottom: 1% !important;
  }
  .btn-endconsumer-container.responsive div button {
    height: 60px !important;
  }
  /* .workshop-type-card {
    margin: 0.75%;
    width: calc(75% - 25px);
  } */
  .workshop-card {
    margin: 1%;
    width: calc(60% - 25px);
  }
  .workshop-img-container {
    height: 215px;
  }
  /* .three-columns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  } */
  .ant-divider {
    background-color: white;
  }
  .footer {
    padding: 30px 24px 5px !important;
    text-align: -webkit-center;
    height: auto;
  }
  .three-columns {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    padding: 0;
  }
  /* .mobile-center {
    float: none !important;
  } */
  .row-margin {
    justify-content: center;
  }
  .mobile-icons {
    font-size: 24px;
  }
}

@media only screen and (max-width: 624px) {
  /* .workshop-type-card {
    margin: 0.75%;
    width: calc(75% - 25px);
  } */
  .right-map-box {
    font-size: 9px;
  }
  .header-pad {
    padding: 104px 24px 14px !important;
    background: white;
    background-image: none !important;
    background-position: none !important;
    background-size: unset;
    height: auto;
  }
  .title-text {
    font-size: 24px;
  }
  .title-text > strong {
    font-size: 24px;
  }
  .workshop-card {
    margin: 1%;
    width: calc(75% - 25px);
  }
  .workshop-img-container {
    height: 190px;
  }
  .mapping {
    margin-top: 0 !important;
  }
  .map-box {
    background: #2f66f2;
    color: #ffffff;
    padding: 0 0 0 40px;
    line-height: 20px;
    height: 20px;
    font-size: 9px;
  }
  .map-box-off {
    background: #979797;
  }
  .pad {
    width: 22px;
    position: relative;
    margin-left: -28px;
  }
  .arrow {
    border-top: 10px solid transparent;
    border-left: 10px solid #2f66f2;
    border-bottom: 10px solid transparent;
  }
  .arrow-on {
    border-left: 10px solid #3f45f6;
  }
  .arrow-off {
    border-left: 10px solid #979797;
  }
  .arrow-neutral {
    border-left: 10px solid #ffffff;
  }
}

@media only screen and (max-width: 575.5px) {
  .filter.ant-popover-placement-top {
    max-width: 60% !important;
    position: absolute !important;
  }
}

@media only screen and (max-width: 575.5px) {
  .header-sizing {
    font-size: 10px;
  }
}

@media only screen and (max-width: 411px) {
  .number-coloring {
    font-size: 16px;
  }
  .header-sizing {
    font-size: 9px;
  }
  .left-items {
    inline-size: max-content;
  }
  .filter-input div.ant-popover-content div.ant-popover-inner {
    max-height: 250px;
    overflow-x: hidden;
  }
  .input-filter input {
    font-size: 12px;
  }
  .search {
    font-size: 12px;
  }
  .second-title-text {
    font-size: 14px;
  }
  .mobile-size {
    font-size: 12px;
  }
  .mobile-title {
    font-size: 19px;
  }
  .mobile-size input {
    font-size: 12px;
  }
  .mobile-size button {
    font-size: 12px;
  }
  .mobile-pad {
    padding: 30px 24px 25px !important;
  }
  .btn-endconsumer-container.responsive {
    bottom: 1% !important;
  }
  .btn-endconsumer-container.responsive div button {
    height: 60px !important;
  }
  .workshop-card {
    margin: 2%;
    width: calc(100% - 25px);
  }
  .workshop-img-container {
    height: 170px;
  }
}

@media only screen and (max-width: 360px) {
  .header-sizing {
    padding: 5px;
  }
  .map-box {
    font-size: 8px;
  }
  .pad {
    width: 20px;
  }
  .btn-endconsumer-container.responsive {
    bottom: 1% !important;
  }
  .btn-endconsumer-container.responsive div button {
    height: 60px !important;
  }
  /* .workshop-type-card {
    margin: 0.75%;
    width: calc(75% - 25px);
  } */
  .workshop-card {
    margin: 1%;
    width: calc(95% - 25px);
  }
  .end-button {
    width: 100%;
  }
}

@media only screen and (max-width: 360px) {
  .map-box {
    background: #2f66f2;
    color: #ffffff;
    padding: 0 0 0 36px;
    line-height: 16px;
    height: 16px;
    font-size: 5px;
  }
  .number-coloring {
    font-size: 12px;
  }
  .right-map-box {
    font-size: 7px;
  }
  .map-box-off {
    background: #979797;
  }
  .pad {
    margin-left: -26px;
  }
  .arrow {
    border-top: 8px solid transparent;
    border-left: 8px solid #2f66f2;
    border-bottom: 8px solid transparent;
  }
  .arrow-on {
    border-left: 8px solid #3f45f6;
  }
  .arrow-off {
    border-left: 8px solid #979797;
  }
  .arrow-neutral {
    border-left: 8px solid #ffffff;
  }
}

@media only screen and (max-height: 760px) {
  /* .info-div {
    height: 100vh !important;
  } */
}

@media only screen and (max-height: 667px) {
  .side-sub-title .end-button {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 449px) {
  .div-header-aboutus {
    /* width: 647px; */
    padding-top: 20px;
    height: 50px;
    width: 250px;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 250px 0px, 75px 250px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contact {
    /* width: 647px; */
    padding-top: 20px;
    height: 50px;
    width: 250px;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 250px 0px, 75px 250px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-rewards {
    /* width: 647px; */
    padding-top: 20px;
    height: 50px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .rewards-header-container {
    height: 70px;
    width: 280px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 450px) {
  .div-header-aboutus {
    /* width: 647px; */
    padding-top: 20px;
    height: 74px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 300px 0px, 75px 300px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contact {
    /* width: 647px; */
    padding-top: 20px;
    height: 74px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 300px 0px, 75px 300px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-rewards {
    /* width: 647px; */
    padding-top: 20px;
    height: 74px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .rewards-header-container {
    height: 100%;
    width: 300px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 769px) {
  .div-header-aboutus {
    /* width: 647px; */
    padding-top: 25px;
    height: 84px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 400px 0px, 75px 400px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contact {
    /* width: 647px; */
    padding-top: 25px;
    height: 84px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 400px 0px, 75px 400px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-rewards {
    /* width: 647px; */
    padding-top: 25px;
    height: 84px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .rewards-header-container {
    height: 100%;
    width: 400px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 2700px) {
  .div-header-aboutus {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 550px 0px, 75px 550px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contact {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 550px 0px, 75px 550px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-rewards {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .rewards-header-container {
    height: 100%;
    width: 570px;
  }
}

@media only screen and (min-width: 2701px) {
  .div-header-aboutus {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 700px 0px, 75px 700px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-contact {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 500px 0px, 75px 500px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-rewards {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(0 0, 500px 0px, 75px 500px, 0px 100px);
    display: flex;
    align-items: center;
    padding-left: 5%;
  }
  .div-header-rewards {
    /* width: 647px; */
    padding-top: 50px;
    height: 94px;
    width: 100%;
    background-color: #2f66f2;
    clip-path: polygon(75px 0, 100% 0px, 100% 100%, 0px 100px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* align-self: flex-end; */
    /* justify-self: flex-end; */
    padding-right: 5%;
  }
  .rewards-header-container {
    height: 100%;
    width: 570px;
  }
}

.amazon-cards {
  border-width: 1px;
  border-style: solid;
  border-color: #a8a8a8;
}

@keyframes backgroundFiltersColorPalette {
  0% {
    background: #2f66f2;
  }
  25% {
    background: #bcc4db;
  }
  50% {
    background: #2f66f2;
  }
  75% {
    background: #bcc4db;
  }
  100% {
    background: #2f66f2;
  }
}

.filter-animation {
  animation-name: backgroundFiltersColorPalette;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  height: auto;
  padding: 30px 18px;
  border-radius: 0 0 10px 10px;
  /*background: #2f66f2;*/
}

.avatar-color {
  filter: hue-rotate(60deg) brightness(500%);
}

.nav-profile-hello {
  display: flex;
  justify-self: center;
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  max-lines: 1 !important;
  height: 70px;
}

.nav-profile-name {
  color: white;
  margin-left: 0px;
  margin-right: 5px;
  max-lines: 1 !important;
  height: 70px;
}

.dashboard-super-user {
  padding-top: 10px;
  /* padding-top: 50px; */
  padding-bottom: 50px;
  /* padding: 5%; */
  padding-left: 5%;
  padding-right: 5%;
}

.dashboard-super-user-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dsh-su-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dsh-su-options {
  display: flex;
  padding-bottom: 15px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.dashboard-super-user-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dsh-su-top-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dsh-su-little-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9.5%;
  height: 110px;
  border: 1px solid #dbdbdb;
  background-color: white;
  user-select: none;
  border-radius: 10px;
}

.dsh-su-little-cards:hover {
  background-color: rgb(247, 247, 247);
  /* background-color: #ebe9e9; */
  /* cursor: pointer; */
}

.dsh-su-little-card-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: -20px;
}
.dsh-su-little-card-content .ant-skeleton {
  width: 30px;
  margin: 0 auto;
}
.dsh-su-little-card-content .ant-skeleton .ant-skeleton-content {
  padding-left: 20px;
  padding-top: 5px;
}
.dsh-su-little-card-content .ant-skeleton .ant-skeleton-title {
  width: 25px;
  height: 35px;
  border-radius: 5px;
}

.dsh-su-bottom-cards {
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.dsh-su-left-content {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 77.6%;
  /* height: 772px; */
  border: 1px solid #dbdbdb;
  background-color: white;
  border-radius: 10px;
}

.dsh-su-right-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 21.4%;
  margin-left: 1.2%;
  height: 772px;
  /* background-color: white; */
  /* border: 1px solid #dbdbdb; */
}

.dsh-su-title-appointment {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  height: 23px;
  padding: 15px;
}

@media only screen and (max-width: 1835px) {
  .dsh-su-div-ds {
    display: flex;
    flex-direction: column;
    width: 1200px;
    /* height: 100%; */
  }
}

@media only screen and (min-width: 1836px) {
  .dsh-su-div-ds {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.dsh-su-table {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.dsh-su-table-rev {
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  overflow-x: scroll;
  width: 100%;
  /* height: 92.9%; */
}

.dsh-su-table-ds {
  font-size: 10px;
  text-align: center;
}

.dsh-su-table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  font-size: 13px;
  border-bottom: 1px solid #dbdbdb;
}

.dsh-su-table-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  font-size: 13px;
}

.dsh-su-table-index {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
}

.dsh-su-table-item-index {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin: 2px;
}

.dsh-su-table-item-index-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.dsh-su-large-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 110px;
  border: 1px solid #dbdbdb;
  background-color: white;
  border-radius: 10px;
}

/* ==========================================================================
  test
  ========================================================================== */
.dahsboard-su-metrics {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.dahsboard-su-metrics .dsh-su-large-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  width: calc(25% - 10px);
}
.dahsboard-su-metrics .dsh-su-large-cards p {
  color: #444;
  font-family: var(--font-family);
  margin: 0;
  line-height: 1;
}
.dahsboard-su-metrics .dsh-su-large-cards .title {
  font-size: 18px;
  cursor: pointer;
}
.dahsboard-su-metrics .dsh-su-large-cards .content {
  font-size: 28px;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .dahsboard-su-metrics {
    gap: 20px;
  }
  .dahsboard-su-metrics .dsh-su-large-cards {
    width: calc(50% - 10px);
  }
}
@media (max-width: 576px) {
  .dahsboard-su-metrics {
    gap: 10px;
  }
  .dahsboard-su-metrics .dsh-su-large-cards {
    width: 100%;
  }
}
/* ==========================================================================
  /test
  ========================================================================== */

.dsh-su-little-bottom-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46.6%;
  height: 110px;
  border: 1px solid #dbdbdb;
  background-color: white;
  border-radius: 10px;
}

.dsh-su-graph-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */ /* test */
  width: 100%;
  height: 380px;
  border: 1px solid #dbdbdb;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
.dsh-su-graph-content h2 {
  font-size: 20px;
  color: #495172;
  margin: 30px auto -35px auto;
}
@media (min-width: 1600px) {
  .dsh-su-graph-content {
    height: 400px;
  }
}
@media (min-width: 1700px) {
  .dsh-su-graph-content {
    height: 450px;
  }
}
@media (min-width: 1900px) {
  .dsh-su-graph-content {
    height: 450px;
  }
}
@media (min-width: 2000px) {
  .dsh-su-graph-content {
    height: 500px;
  }
}
@media (min-width: 2100px) {
  .dsh-su-graph-content {
    height: 500px;
  }
}
@media (min-width: 2200px) {
  .dsh-su-graph-content {
    height: 540px;
  }
}
@media (min-width: 2560px) {
  .dsh-su-graph-content {
    margin: 0 auto;
    max-width: 470px;
    height: 600px;
  }
}

.dsh-su-range-options {
  margin-top: -35px;
}

.su-navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  height: var(--navbar-height);
  width: 100%;
  background-color: #f05822;
  padding-right: 5%;
  padding-left: 5%;
}

.su-nav-username {
  color: white;
  margin-right: 20px;
}

.su-nav-avatar {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  width: 35px;
  height: 35px;
  background-color: #bdbdbf;
  border-radius: 50px;
  margin-right: 20px;
}

.su-nav-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.su-subnav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: var(--sub-navbar-height);
  width: 100%;
  background-color: #ffffff;
  padding-right: 3%;
  padding-left: 3%;
  border-bottom: 1px solid #dbdbdb;
}

.su-subnav-logo {
  height: 80px;
  /* background-color: teal; */
}

.su-subnav-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  flex: 1;
}

.su-subnav-left-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.su-subnav-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.no-padding-left {
  padding-left: 0px !important;
}

.su-subnav-search-form {
  margin-top: 25px;
}

.su-subnav-search-input,
::placeholder {
  /* color: red !important; */
  /* padding-right: 155px; */
  /* width: 100%; */
}

.img-refreshIco {
  width: 30px;
  filter: invert(58%) sepia(93%) saturate(6221%) hue-rotate(216deg)
    brightness(96%) contrast(98%);
}

.img-calendarPlusIco {
  width: 40px;
  margin-top: -2.5px;
  filter: hue-rotate(60deg) brightness(500%);
}

.img-editIco {
  width: 20px;
  margin-top: -5.5px;
  margin-left: -2.5px;
  /* filter: hue-rotate(60deg) brightness(500%); */
}

.img-SearchIco {
  width: 26.6px;
  margin-top: -2.5px;
  filter: hue-rotate(60deg) brightness(500%);
}

.menu-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 70px;
  border-radius: 10px;
  margin-bottom: 22px;
  border: none;
  box-shadow: none;
}

.menu-button span {
  margin-left: 10px;
}

.menu-button span svg {
  width: 28.5px;
  height: auto;
}

.menu-items-options {
  display: flex;
  line-height: inherit;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  height: 45px;
  width: 100%;
  border: none;
  box-shadow: none;
  align-items: center;
}

.menu-items-options-su {
  display: flex;
  flex-direction: row;
  line-height: inherit;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  height: 45px;
  width: 100%;
  border: none;
  box-shadow: none;
  align-items: center;
}

.menu-popover {
  min-width: 252px;
  background-color: #f05822;
}

.menu-popover-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  margin-right: 40px;
  margin-left: 15px;
}

/* .menu-popover-align .div-pressable .menu-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.menu-popover-align span {
  font-weight: 300;
}

.menu-items-options-su {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 252px;
  background-color: #f05822;
}

.menu-items-options-su .ant-image {
  max-width: 42px;
}

.div-pressable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.div-pressable :hover {
  cursor: pointer;
  color: #f05822;
}

.menu-popover .ant-divider {
  border-color: white !important;
  margin: 5px;
}

.search-popover {
  display: flex;
  flex-direction: column;
  /* width: 372px; */
  height: 350px;
}

.search-popover-head {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 46px;
  background-color: #f05822;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 15px;
}

.search-popover-subhead {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  background-color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 15px;
}

.search-popover-table {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.search-popover-table-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 150px;
  /* background-color: red; */
}

/* .su-subnav-right-buttons {} */

.dsh-su-little-cards-ec {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8%;
  border: 1px solid #dbdbdb;
  background-color: white;
  user-select: none;
  border-radius: 10px;
}

.su-nav-table-label {
  width: 100%;
  height: 100%;
}

.su-nav-table-container-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 10px;
  user-select: none;
}

.su-nav-table-container-label:hover {
  /* background-color: rgb(247, 247, 247); */
  /* background-color: #ebe9e9; */
  cursor: pointer;
}

.search-popover-cleansed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 372px;
  height: 25px;
}

.search-popover-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 372px;
  height: 25px;
  /* background-color: rgb(223, 119, 119); */
  /* color: white; */
}

.search-popover-charging {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 372px;
  height: 25px;
}

.su-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 750px;
}

.su-create-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  width: 900px;
  margin-top: -20%;
}

.su-create-title {
  height: 54px;
  width: 100%;
  padding: 20px;
  padding-top: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f05822;
}

.su-create-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.su-create-content-scroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  /* overflow-y: scroll; */
  /* padding: 10px; */
  width: 100%;
}

.su-create-save {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
}

.su-create-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.su-create-subform {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.dashboard-super-user-logout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 250px;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: #2f66f2;
}
.bubble-text {
  color: white;
  font-family: emprint-semibold;
  margin: 0;
}
#avatar img {
  object-fit: contain;
}

.div-row {
  display: flex;
  align-items: center;
}

#rating-table .ant-table {
  background: none !important;
}
#rating-table td {
  padding: 0;
}

.rating-icon {
  font-size: 21px;
}

.visible {
  color: #37d16a;
}

.not-visible {
  color: #d40c00;
}

.null-visible {
  color: #dbdbdb;
}

.reported-visible {
  color: white;
  background: #d40c00;
  border-radius: 100%;
  width: 22px;
  text-align: center;
  height: 22px;
  font-size: 14px;
}
.ant-btn[disabled]:hover {
  cursor: not-allowed;
}

.category-no-adecuado .ant-select-selector {
  color: white;
  background: #d40c00 !important;
}
.category-base .ant-select-selector {
  background: #37d16a !important;
}
.category-mejorable .ant-select-selector {
  background: goldenrod !important;
}
.category-estrategico .ant-select-selector {
  color: white;
  background: #336600 !important;
}
.content .ant-modal-content {
  border-radius: 10px;
}
.--button-blue {
  background: var(--blue) !important;
  color: white;
  border-radius: 10px;
}
.--button-blue:hover {
  background: var(--blue-dark) !important;
  color: white;
}
.--button-blue:disabled {
  background: var(--black-15) !important;
  border-color: var(--black-20) !important;
}
.--button-red {
  background: var(--red) !important;
  color: white;
  border-radius: 10px;
}
.--button-red:hover {
  background: var(--red-dark) !important;
  color: white;
}
.--button-red:disabled {
  background: var(--black-15) !important;
  border-color: var(--black-20) !important;
}
.--button-orange {
  background: var(--orange) !important;
  color: white;
  border-radius: 10px;
}
.--button-orange:hover {
  background: var(--orange-dark) !important;
  color: white;
}
.--button-orange:disabled {
  background: var(--black-15) !important;
  border-color: var(--black-20) !important;
}
.su-ws-cards-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 5px;
}
.su-ws-cards-container > div {
  width: 9.5% !important;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  padding: 5px;
}
.su-ws-cards-container > div > p {
  margin-top: 5px;
  margin-bottom: 0px;
  color: #002257;
  font-family: "Emprint-regular";
  text-align: center;
  font-size: 10px;
}
.failures-container {
  padding: 15px;
  border-radius: 10px;
  margin: 0px 8px 20px 8px;
  background-color: #2f66f22b;

  width: 100% !important;
}
.failures-container .failures-title {
  font-size: 14px !important;
  font-family: "EMprint-Semibold";
  color: #444444;
}
.failures-container .failures-info {
  font-size: 14px !important;
  font-family: "EMprint-Regular";
  color: #444444;
}
