.confirmation-modal-container .ant-modal-content {
  border-radius: 10px !important;
  background: white !important;
}

.confirmation-modal-container .ant-modal-body {
  padding: 30px !important;
}

.confirmation-modal-container .ant-modal-content .ant-modal-close-x svg {
  filter: brightness(0%);
  width: 35px;
  height: 35px;
}

.confirmation-modal-container .ant-modal-content .ant-modal-close {
  right: -10px !important;
}

.confirmation-modal-container .confirmation-modal-container-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.confirmation-modal-container .confirmation-modal-title {
  font-size: 25px;
  font-weight: 600;
  color: #0c479d;
}

.confirmation-modal-container .confirmation-modal-message {
  font-size: 16px;
  color: #444;
}

.confirmation-modal-container .confirmation-modal-message a {
  font-weight: 600;
  font-size: 16px;
  color: #0c479d;
  text-decoration: underline;
  cursor: text;
}

.confirmation-modal-container .confirmation-modal-submessage {
  font-size: 16px;
  color: #444;
}

.confirmation-modal-container .confirmation-modal-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.confirmation-modal-container .confirmation-modal-buttons .ok-btn {
  width: 240px;
  height: 40px;
  padding: 5px 16px;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.confirmation-modal-container .confirmation-modal-buttons .cancel-btn {
  width: 240px;
  height: 40px;
  padding: 5px 16px;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
}
