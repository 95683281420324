.menu-items .menu-items-options {
  margin-bottom: 5px;
  font-weight: 400;
  color: #fff;
  background: #f05822 !important;
  transition: all 0.5s ease;
}
.menu-items .menu-items-options:hover {
  background: #f27040 !important;
}
.menu-items .menu-items-options:active,
.menu-items .menu-items-options:focus {
  background: #d7440f !important;
}
.menu-items-options-su {
  font-weight: 400 !important;
}
