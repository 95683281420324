.wheel-spinner  {
  margin: 10px auto;
}
.wheel-spinner  .ant-spin.ant-spin-spinning,
.ant-spin.ant-spin-spinning.wheel-spinner-inline {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.ant-spin.ant-spin-spinning.wheel-spinner-inline {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  overflow: hidden;
}
.wheel-spinner  .ant-spin-blur {
  border-radius: 10px;
  overflow: hidden;
}

.wheel-spinner  .ant-spin.ant-spin-spinning.small,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.small {
  min-height: 80px;
}
.wheel-spinner  .ant-spin.ant-spin-spinning.medium,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.medium {
  min-height: 120px;
}
.wheel-spinner  .ant-spin.ant-spin-spinning.large,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.large {
  min-height: 170px;
}

.wheel-spinner .ant-spin.ant-spin-spinning .wheel-icon,
.ant-spin.ant-spin-spinning.wheel-spinner-inline .wheel-icon {
  height: 150px;
  width: 150px;
  position: relative !important;
  top: unset !important;
  left: unset !important;
}
.wheel-spinner .ant-spin.ant-spin-spinning .wheel-icon svg,
.ant-spin.ant-spin-spinning.wheel-spinner-inline .wheel-icon svg {
  height: 150px;
  width: 150px;
  color: var(--orange);
  -webkit-animation: rotate-center 3s linear infinite both;
  animation: rotate-center 3s linear infinite both;
}
.wheel-spinner .ant-spin.ant-spin-spinning.large .wheel-icon,
.wheel-spinner .ant-spin.ant-spin-spinning.large .wheel-icon svg,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.large .wheel-icon,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.large .wheel-icon svg {
  height: 150px;
  width: 150px;
}

.wheel-spinner .ant-spin.ant-spin-spinning.medium .wheel-icon,
.wheel-spinner .ant-spin.ant-spin-spinning.medium .wheel-icon svg,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.medium .wheel-icon,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.medium .wheel-icon svg {
  height: 100px;
  width: 100px;
}
.wheel-spinner .ant-spin.ant-spin-spinning.small .wheel-icon,
.wheel-spinner .ant-spin.ant-spin-spinning.small .wheel-icon svg,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.small .wheel-icon,
.ant-spin.ant-spin-spinning.wheel-spinner-inline.small .wheel-icon svg {
  height: 50px;
  width: 50px;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



/*
.ant-spin.ant-spin-spinning.wheel-spinner {
  display: flex;
  position: relative;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}
.wheel-spinner .wheel-icon {
  height: 150px;
  width: 150px;
  position: relative !important;
  top: unset !important;
  left: unset !important;
}
.wheel-spinner .wheel-icon svg,
.wheel-spinner.large .wheel-icon svg {
  height: 150px;
  width: 150px;
  color: var(--orange);
  -webkit-animation: rotate-center 3s linear infinite both;
  animation: rotate-center 3s linear infinite both;
}
.wheel-spinner.medium .wheel-icon svg {
  height: 100px;
  width: 100px;
}
.wheel-spinner.small .wheel-icon svg {
  height: 50px;
  width: 50px;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
*/
