.banners-dashboard {
  width: 95%;
  margin: 25px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.banners-dashboard h1 {
  color: var(--orange);
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 20px;
  font-weight: 600;
}
/* .banners-dashboard .ant-divider { */
/*   background-color: var(--orange) !important; */
/* } */
.banners-dashboard .table-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 30px auto;
  gap: 15px;
  border: 2px solid var(--orange);
  padding: 20px;
  margin: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
}
.banners-dashboard .table-container .table-idenfity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.banners-dashboard .table-container .table-idenfity h2 {
  color: var(--orange);
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0px;
  font-weight: 600;
}
.banners-dashboard .table-container .table-idenfity .identify-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.banners-dashboard .table-container .table-idenfity .ant-btn {
  width: 180px;
  height: 40px;
  border-radius: 5px !important;
}
.banners-dashboard .table-container .table-idenfity .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
}
.banners-dashboard
  .table-container
  .table-idenfity
  .ant-btn
  span:not(.anticon) {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 5px;
}
.banners-dashboard .table-container .ant-btn.ant-btn-icon-only {
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
.banners-dashboard .table-container .ant-table {
  width: 100%;
  overflow-x: scroll;
}

/* ==========================================================================
  Pages grid
  ========================================================================== */
.banners-dashboard .tables-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}
.banners-dashboard .tables-grid > .table-container {
  width: calc(50% - 15px);
  min-width: 430px;
}

@media (max-width: 950px) {
  .banners-dashboard .tables-grid > .table-container {
    width: 100%;
    min-width: 430px;
  }
}
